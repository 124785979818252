<template>
  <div
    v-if="!isLoading"
    class="d-flex flex-column align-items-center"
  >
    <b-card style="width:70%">
      <div class="text-center mb-3">
        <h2 class="text-primary">
          {{ $t('src.layouts.delivery-summary.timeline.deliveryS') }}
        </h2>
      </div>
      <app-timeline>
        <YourInformation
          :order-data="order"
          @updateOrder="updateOrder"
        />
        <DeliveryOverview
          :delivery-id="order.reference_id"
          :user-id="order.user.id"
          :status="order.status"
          :total-spent="order.price"
        />
        <DateTimelineItem
          :order-data="order"
          @updateOrder="updateOrder"
        />
        <AddressesTimelineItem
          :pick-up-address="order.sender ? order.sender.complete : ''"
          :drop-off-address="order.recipient ? order.recipient.complete : ''"
        />
        <ProductTimelineItem
          :category="order.category.text"
          :items="items"
        />
        <DownloadableTemplate
          :title="$t('src.layouts.delivery-summary.timeline.deliveryO')"
          :btn-text="$t('src.layouts.delivery-summary.timeline.downloadH')"
          :downloaded-file="fileDownloaded"
          :type-of-file="'do'"
          @downloadFile="handleDownloadFile"
        />
        <DownloadableTemplate
          :title="$t('src.layouts.delivery-summary.timeline.coverN')"
          :btn-text="$t('src.layouts.delivery-summary.timeline.downloadH')"
          :downloaded-file="fileDownloaded"
          :type-of-file="'cover'"
          @downloadFile="handleDownloadFile"
        />
        <DownloadableTemplate
          :title="$t('src.layouts.delivery-summary.timeline.goodRN')"
          :btn-text="$t('src.layouts.delivery-summary.timeline.downloadH')"
          :downloaded-file="fileDownloaded"
          :type-of-file="'grn'"
          @downloadFile="handleDownloadFile"
        />
        <DownloadInvoice
          v-if="order.invoice || order.payment"
          :order="order"
        />
        <app-timeline-item icon="UploadIcon">
          <h4>{{ $t('src.layouts.delivery-summary.timeline.proofOD') }}</h4>
          <ProofOfDelivery
            :drop-off-photo="order.delivered_photo"
            :pickup-photo="order.pickup_photo"
            :good-return-photo="order.good_return_photo"
          />
        </app-timeline-item>
      </app-timeline>
      <b-container class="text-center">
        <b-button
          type="button"
          variant="primary"
          class="mt-2"
          @click="$router.go(-1)"
        >{{ $t('src.layouts.delivery-summary.timeline.back') }}</b-button>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  AddressesTimelineItem,
  DateTimelineItem,
  DeliveryOverview,
  ProductTimelineItem,
  YourInformation,
  DownloadInvoice,
  ProofOfDelivery,
  DownloadableTemplate,
} from '@/components/DeliverySummary'
import { mapGetters } from 'vuex'

export default {
  components: {
    AddressesTimelineItem,
    DateTimelineItem,
    DeliveryOverview,
    ProductTimelineItem,
    YourInformation,
    DownloadInvoice,
    DownloadableTemplate,
    ProofOfDelivery,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      fileDownloaded: '',
    }
  },
  computed: {
    ...mapGetters({
      order: 'orders/order',
      items: 'orders/items',
      isLoading: 'orders/isLoading',
    }),
  },
  created() {
    this.$store.dispatch('orders/fetchOrder', { orderId: this.$route.params.id })
  },
  methods: {
    async handleDownloadFile(type) {
      this.fileDownloaded = type
      const response = await this.$http.get(`/b2b/${type}/id/${this.order.id}`, { responseType: 'blob' })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', `${type}-${this.order.id}.pdf`)
      document.body.appendChild(fileLink)
      fileLink.click()
      this.fileDownloaded = ''
    },
    async updateOrder() {
      this.$store.dispatch('orders/updateOrder', { orderId: this.$route.params.id, formData: this.order })
    },
  },
}
</script>
